$brand_color1: #242529;
$brand_color2: #5DE094;
$brand_color3: #8038f5;
$brand_color4: #77327B;
$brand_color5: #51459E;
$brand_color6: #E34D4D;
$brand_color7: #C83333;
$brand_dark_color:#0B0C0D;
$white_color: #ffffff;
$light_color: #ffffffb3;
$light_color2: #6F767E;
$added_mob_color: #191B1F;


$white_opacity7: rgba(255, 255, 255, 0.7);
$white_color_half: rgba(255, 255, 255, 0.5);
$white_opacity05: rgba(255, 255, 255, 0.05);
$white_opacity3: rgba(255, 255, 255, 0.3);
$white_opacity1: rgba(255, 255, 255, 0.1);



$brand_color2_opacity1: rgba(93, 224, 148, 0.1);
$brand_color3_opacity2: rgba(128, 56, 245, 0.2);
$brand_color7_opacity1: rgba(200, 51, 51, 0.1);

$bdr_rds1: 5px;
$bdr_rds2: 10px;
$bdr_rds3: 14px;

$p10: 10px;
$p15: 15px;
$p20: 20px;

